<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
        LordTech
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Verify Email 🔒
        </b-card-title>
        <b-card-text class="mb-2">
         Please Input The Verification Code Sent To Your Email
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Verification code"
              label-for="verification-code"
            >
              <validation-provider
                #default="{ errors }"
                name="Verification"
                rules="required"
              >
                <b-form-input
                  id="verification-code"
                  v-model="userCode"
                  :state="errors.length > 0 ? false:null"
                  name="verification-code"
                  type="number"
                   oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                   maxlength="6"
                  placeholder="123456"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="sendcode"
            >
               <b-spinner v-if="sendcode"
          small
          type="grow"
        />  
            VERIFY
            </b-button>
       
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-spinner variant="primary" label="Text Centered" v-if="load"/>
          <b-link @click="sendemailcode" v-else>
            <feather-icon icon="MailIcon" /> Resend Email
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BSpinner, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BSpinner,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userCode: '',
      // validation
      required,
      load:false,
      sendcode: false,
      email,
    }
  },
  methods: {


       async verifyaccount() {

     
this.sendcode = true;


     var data = await this.$store.dispatch('verifycode', {
         verify_code: this.userCode,
      });  
  
if (data.status == true) {
  this.sendcode = false;

    this.$router.push({name: 'dashboard'});
this.$swal({
        title: 'Email Verified!',
        text: 'Welcome To Your LordTech Dashboard, we look forward to seemless transactions!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

  
}else {

    console.log( Object.prototype.toString.call(data.message))
  if (data.object == true) {
       var data = JSON.parse(data.message)
       console.log(data);
    this.sendcode = false;
    for (var k in data) {
        if (data.hasOwnProperty(k)) {

            this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    




  }else {

this.sendcode = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,
                    },
                  })

  }
}





 

   },


   async sendemailcode() {

     
     this.load = true;


     var data = await this.$store.dispatch('sendverifycode');  
  
if (data.status == true) {
  this.load = false;


this.$swal({
        title: 'Verification Code has been sent Successfully!',
 
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

  
}else {
    this.load = false;

       this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data.message,
                    },
                  })


}





 

   },





    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.verifyaccount();
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
